import React, { Component } from 'react';
import WOW from 'wow.js'

class ServiceHouseKeeping extends Component {

   componentDidMount() {
      const wow = new WOW();
      wow.init();
    }

  render() {

    if(this.props.data){
      var jobDescription = this.props.data.gasadowmi.map((item, i ) => {
         return (

            <div className="description wow fadeInUp" key={item.jobSpecification}>
               <h3>{item.jobSpecification} <em className="engtitle "><span>&bull;</span>{item.graduated}</em></h3>
               
               <p className="info">{item.jobDescription}</p>


               {item.descriptions.map((desc, ii) => {
                  return (
                     //가사도우미가 계속 반복되서 리핏됨. 이부분을 2줄 위에로 뺄수 있는 방법을 알아봐야됨
                     <div><span>&bull;{desc.name}</span></div>
                  );
               }
               )}    
            </div>
         );
      });
    }

    return (
      <section id="serviceHouseKeeping">

       <div className="row gasadowmi">
          <div className="three columns header-col">
             <h1><span>가사 도우미</span></h1>
          </div>

          <div className="nine columns main-col">
             <div className="row item">
                <div className="twelve columns">
                  <div id="display-gasa">
                     {jobDescription}
                  </div>
                </div>
             </div>
          </div>
       </div>
   </section>
    );
  }
}

export default ServiceHouseKeeping;
