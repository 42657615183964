import React, { Component } from 'react';
import ReactGA from 'react-ga';
import $ from 'jquery';
import './App.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import About from './Components/About';
import Resume from './Components/Resume';
import ServiceHouseKeeping from './Components/ServiceHouseKeeping';
import ServiceChildCare from './Components/ServiceChildCare';
import ServiceBabyCare from './Components/ServiceBabyCare';
import ServiceSeniorCare from './Components/ServiceSeniorCare';
import ServiceOneDayCare from './Components/ServiceOneDayCare';

import Contact from './Components/Contact';
import Testimonials from './Components/Testimonials';
import Portfolio from './Components/Portfolio';
import WOW from 'wow.js'

class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      foo: 'bar',
      resumeData: {}
    };

    ReactGA.initialize('UA-110570651-1');
    ReactGA.pageview(window.location.pathname);

  }

  getResumeData(){
    $.ajax({
      url:'/resumeData.json',
      dataType:'json',
      cache: false,
      success: function(data){
        this.setState({resumeData: data});
      }.bind(this),
      error: function(xhr, status, err){
        console.log(err);
        alert(err);
      }
    });
  }

  componentDidMount(){
    this.getResumeData();
    const wow = new WOW();
    wow.init();
  }

  render() {
    return (
      <div className="App">
        <Header data={this.state.resumeData.main}/>
        <About data={this.state.resumeData.main}/>
        {/* <Resume data={this.state.resumeData.resume}/> */}
        <ServiceHouseKeeping data={this.state.resumeData.serviceHouseKeeping}/>
        <ServiceChildCare data={this.state.resumeData.serviceChildCare}/>
        <ServiceBabyCare data={this.state.resumeData.serviceBabyCare}/>
        <ServiceSeniorCare data={this.state.resumeData.serviceSeniorCare}/>
        <ServiceOneDayCare data={this.state.resumeData.serviceOneDayCare}/>
        
        {/* <Portfolio data={this.state.resumeData.portfolio}/> */}
        <Testimonials data={this.state.resumeData.testimonials}/>
        <Contact data={this.state.resumeData.main}/>
        <Footer data={this.state.resumeData.main}/>
      </div>
    );
  }
}

export default App;
