import React, { Component } from 'react';
import WOW from 'wow.js'

class ServiceChildCare extends Component {

   componentDidMount() {
      const wow = new WOW();
      wow.init();
    }

  render() {

    if(this.props.data){

      var jobDescription = this.props.data.childcaredowmi.map((item, i ) => {
         return (

            <div className="description wow fadeInUp" key={item.jobSpecification}>
               <h3>{item.jobSpecification} 
                  <em className="engtitle ">
                     {item.graduated 
                        ?  <span>&bull;{item.graduated}</span> 
                        : <div/>}
                  </em>
               </h3>
               
               <p className="info">{item.jobDescription}</p>


               {item.descriptions.map((desc, ii) => {
                  return (
                     <div className="desc" >
                        {desc ?  <span>&bull;{desc.name}</span> : <div/> }
                     </div> 
                  );
               }
               )}    
            </div>
         );
      });
    }

    return (
      <section id="serviceChildCare">

       <div className="row childcaredowmi">
          <div className="three columns header-col">
             <h1><span>육아 도우미</span></h1>
          </div>

          <div className="nine columns main-col">
             <div className="row item">
                <div className="twelve columns">
                  <div id="display-gasa">
                     {jobDescription}
                  </div>
                </div>
             </div>
          </div>
       </div>
   </section>
    );
  }
}

export default ServiceChildCare;
